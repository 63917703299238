import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./Componets/home/home";
import LoginTicket from "./Componets/login/login";

function App() {
 
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/LoginTicket">
          <LoginTicket/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

import React, {useState, useEffect} from "react";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../assets/imagenes/log.png";
import swal from "sweetalert";
import useAxios from "axios-hooks";
import TextField from '@material-ui/core/TextField';
import {Redirect} from "react-router-dom";
import jwt from "jsonwebtoken";


const useStyles= makeStyles((theme) =>({
    fondo: {
        backgroundColor:"white",
        marginTop:20,
        minHeight: 530,
    }
    ,Imagen: {
        maxHeight: "65px",
        maxWidth: "380px",
        width: "auto",
        height: "auto",
        verticalAlign: "middle",
        marginTop: "16px",
    }
    ,btnblue: {
        color:"#7B241C",
        fontSize: "18px",
        margin:"#7b2a29",
        padding: "10px",
        textAlign:"center",
        width: "50%",
        
      }
    ,Titulo: {
        color:"#7b2a29",
        fontSize: "22px",
        textAlign: "center",
    }
    ,Titulo2: {
        color:"#7b2a29",
        fontSize: "22px",
        textAlign: "rigth",
    }
    ,centrar:{
        padding: "10px",
        textAlign:"center",
        width: "50%",
    }
}))

export default function Login(){
    const classes = useStyles();
    const[redirect, setRedirect] = useState(false);
    const [usuario, setUsuario]= useState("");
    const[contra, setContra]= useState("");

    const[
        {
           data: inicioUsuarioData,
           loading: inicioUsuarioLoading,
           error: inicioUsuarioError,
        },executeInciarSesion,
    ] = useAxios(
        {
           url: 'https://apicrm.dublock.com/inicioUsuario',
           method: "POST",
        },
        {
           useCache: false, manual:true
        }
    );
    useEffect(() => {
        if (localStorage.getItem("userData")){
           setRedirect(true);
        }}, []) 

    useEffect(() => {
        function checkData() {
            if (inicioUsuarioData){
                if( inicioUsuarioData.error !== 0){
                    swal("Error", "Usuario o Contraseña incorrectos", "warning");
                    setRedirect(false);
                }
                else{
                    const token = jwt.sign({
                        data: {
                            userData: inicioUsuarioData.usuario[0]
                        }
                    },"secretkey");
                    localStorage.setItem("userData", token);                  
                    setRedirect(true);
                }
            }
        }checkData();
    }, [inicioUsuarioData]);

    if(inicioUsuarioLoading ){
        return <div>Cargando...</div>
    } 
    if(inicioUsuarioError ){
        return <Typography> Error de conexion</Typography>
    }
    /* const handleClickMostrarContra=()=>{
        const tipo = document.getElementById("contra");
        if(tipo.type === "password"){
            tipo.type = "text";
        }else{
            tipo.type ="password";
        }
    } */
    const handleClickEntrar=()=>{
        if(usuario.trim() === "") {
            swal("Error", "Ingrese su usuario", "warning");
        }
        else if(contra.trim() === "") {
            swal("Error", "Ingrese su Contraseña", "warning");
        }  
        else { 
            executeInciarSesion({
                data: {
                    usuario: usuario.trim(),
                    pwd: contra.trim(),
                }
            })
        }
    }      
    return redirect ? (
        <Redirect to="/" />) : (
        <Container maxWidth="md" className={classes.fondo}>
            <Grid container> 
                <Grid item xs={12}>
                    <img src={Logo} alt="Logo" className={classes.Imagen}></img>
                    <Typography className={classes.Titulo}><h3>Inicio de Sesión </h3></Typography> 
                </Grid > 
                <Grid container spacing={1} >
                    <Grid item xs={12}  style={{textAlign:"center"}}>
                        <TextField className={classes.centrar}
                            autoFocus
                            margin="dense"
                            id="usuario"
                            label="Correo..."
                            type="email"
                            fullWidth
                            value={usuario}
                            onChange={(e) => {
                                setUsuario(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"center"}} >
                        <TextField className={classes.centrar}
                            autoFocus
                            margin="dense"
                            id="contra"
                            label="Contraseña..."
                            type="password"
                            fullWidth
                            value={contra}
                            onChange={(e) => {
                                setContra(e.target.value);
                            }}
                        >  
                        </TextField>  
                    </Grid>
                    {/* <button class="primary" type="button" onclick={handleClickMostrarContra}>Mostrar Contraseña</button> */}
                    <br/>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                    <Button variant= "outlined" onClick={handleClickEntrar} className={classes.btnblue} type="submit" display="grid" >Entrar</Button>
                    </Grid>
                </Grid>
            </Grid> 
        </Container>
    )
}
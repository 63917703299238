import { Container, Grid, Typography, Button } from "@material-ui/core";
import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../assets/imagenes/log.png";
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import swal from "sweetalert";
import moment from "moment";
import useAxios from "axios-hooks";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Redirect} from "react-router-dom";
import jwt from "jsonwebtoken";

const useStyles= makeStyles((theme) =>({
  fondo: {
    backgroundColor:"white",
    marginTop:30,
    minHeight: 450,
  }
  ,Imagen: {
    maxHeight: "65px",
    maxWidth: "380px",
    width: "auto",
    height: "auto",
    verticalAlign: "middle",
    marginTop: "16px",
  }
  ,Titulo: {
    color:"#7b2a29",
    fontSize: "25px"
  }
  ,Titulo2: {
    color:"#7b2a29",
    fontSize: "22px",
    textAlign: "center",
  }
  ,btnblue: {
    color:"#2E4053 ",
    fontSize: "18px",
    textAlign: "center",
    margin:"#7b2a29",
  }
  ,Menu:{
    listStyle: "none",   
  }
  ,root: {
    width: '100%',
  }
  ,tblhstts: {
    padding: "5px",
    lineHeight: ".5rem",
  }
  ,paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  textFile:{
    width:'90%',
  }
  ,gridspace:{
    padding:"2%",
    textAlign: "right",
  }
  ,greys:{
    color:"#34495E"
  }
  ,buttondelete:{
    color:"#A11414",
    fontSize: "11px",
    textAlign: "center",
    margin:"#A11414",
    display: "-webkit-inline-box",
    marginTop:"10px"
  }
}))

function createData(num_ticket, asunto, modulo, estatus, version, fecha, idStatus, usuario) {
  return { num_ticket, asunto, modulo, estatus, version, fecha, idStatus, usuario };
}
 
let rowsTicketsDt = [
];
  
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
  
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
    /* puede ser aqui */
}
  
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/* Mostrar las columnas y conexion con bd */
const headCells = [
  { id: 'num_ticket', numeric: false, disablePadding: true, label: '#' },
  { id: 'asunto', numeric: true, disablePadding: false, label: 'Asunto' },
  { id: 'modulo', numeric: true, disablePadding: false, label: 'Módulo' },
  { id: 'usuario', numeric: true, disablePadding: false, label: 'Usuario' },
  { id: 'estatus', numeric: true, disablePadding: false, label: 'Estatus' },
  { id: 'version', numeric: true, disablePadding: false, label: 'Versión' },
  { id: 'fecha', numeric: true, disablePadding: false, label: 'Fecha' },
];
  
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
  
}/* TABLA DE CAMBIOS DE ESTATUS */

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
 


/* Funcion Home  */
export default function Home (){
  const classes = useStyles();
  const[order, setOrder] = useState("desc");
  const[orderBy, setOrderBy] = useState("num_ticket");
  const[selected, setSelected] = useState([]);
  const[page, setPage] = useState(0);
  const[open, setOpen] = useState(false);
  const[asunto, setAsunto] = useState("");
  const[modulo, setModulo] = useState(0);
  const[version, setVersion] = useState("");
  const[comentario, setComentario] = useState("");
  const[archivos, setArchivos] = useState(null);
  const[detNumTicket, setDetNumTicket] = useState(0);
  const[detAsunto, setDetAsunto] = useState("");
  const[detModulo, setDetModulo] = useState(0);
  const[detStatus, setDetStatus] = useState("");
  const[detVersion, setDetVersion] = useState("");
  const[detComentario, setDetComentario] = useState("");
  const[detFecha, setDetFecha]=useState("");
  const[detArchivos, setDetArchivos] = useState([]);
  const[rows, setRows] = useState([]);
  const[modulos, setModulos] = useState([]);
  const[status, setStatus] = useState([]);
  const[filtro, setFiltro] = useState("");
  const[redirect, setRedirect] = useState(false);
  const[userData, setUserData] = useState([]);
  const[detUsuarioCreador, setDetUsuarioCreador] = useState("");
  const[historial, setHistorial] = useState([]);

  const[showSection, setShowSection]=useState(0);
 
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const[
    {
      data: getTicketsData,
      loading: getTicketsLoading,
      error: getTicketsError,
  
    },
    executeGetTickets,
    
  ] = useAxios(
    {
      url: 'https://apicrm.dublock.com/getTickets',
      method: "GET",  
    },
    {
      useCache: false,
    }
  );

  const[
    {
      data: getModulosTicketData,
      loading: getModulosTicketLoading,
      error: getModulosTicketError,
    },
    
  ] = useAxios(
    {
      url: 'https://apicrm.dublock.com/getModulosTicket',
      method: "GET",
    },
    {
      useCache: false,
    }
  );const[
    {
      data: getStatusTicketData,
      loading: getStatusTicketLoading,
      error: getStatusTicketError,
    },
    
  ] = useAxios(
    {
      url: 'https://apicrm.dublock.com/getStatusTicket',
      method: "GET",
    },
    {
      useCache: false,
    }
  );
  const[
    {
      data: insertarTicketsData,
      loading: insertarTicketsLoading,
      error: insertarTicketsError,
    }, executeInsertarTickets, 

  ] = useAxios(
    {
      url: 'https://apicrm.dublock.com/insertarTickets',
      method: "POST",
    },
    {
      useCache: false, manual:true
    }
  );
  const[
    {
      data: detallesTicketData,
      loading: detallesTicketLoading,
      error: detallesTicketError,
    }, executeDetallesTicket, 

  ] = useAxios(
    {
      url: 'https://apicrm.dublock.com/detallesTicket',
      method: "GET",
    },
    {
      useCache: false, manual:true
    }
  );
  const[
    {
      data: modificarTicketData,
      loading: modificarTicketLoading,
      error: modificarTicketError,
    }, executeModificarTickets, 

  ] = useAxios(
    {
      url: 'https://apicrm.dublock.com/modificarTicket',
      method: "POST",
    },
    {
      useCache: false, manual:true
    }
  );
  const[
    {
      data: agregarImagenTicketData,
      loading: agregarImagenTicketLoading,
      error: agregarImagenTicketError,
    }, executeAgregarImagenTicket, 

  ] = useAxios(
    {
      url: 'https://apicrm.dublock.com/agregarImagenTicket',
      method: "POST",
    },
    {
      useCache: false, manual:true
    }
  );
  const[
    {
      data: eliminarImagenTicketData,
      loading: eliminarImagenTicketLoading,
      error: eliminarImagenTicketError,
    }, executeEliminarImagenTicket, 

  ] = useAxios(
    {
      url: 'https://apicrm.dublock.com/eliminarImagenTicket',
      method: "DELETE",
    },
    {
      useCache: false, manual:true
    }
  );
  useEffect(() => {
    if (localStorage.getItem("userData")){
      try{
        const decodedToken = jwt.verify(localStorage.getItem("userData"), "secretkey");
        setUserData(decodedToken.data.userData);
        console.log(decodedToken.data.userData);
      }
      catch(e){
        localStorage.removeItem("userData");
        setRedirect(true);
      }
    } else{
      setRedirect(true);
    }
  }, []) 

  useEffect(() => {
    function checkData() {
      if (getTicketsData){
        rowsTicketsDt=[];
        for(
          let x = 0;
          x< getTicketsData.tickets.length;
          x++
        ){
            /* Trae los datos de la base de datos */ 
            rowsTicketsDt.push(createData(getTicketsData.tickets[x].id, getTicketsData.tickets[x].Asunto, getTicketsData.tickets[x].Modulo, getTicketsData.tickets[x].Estatus,getTicketsData.tickets[x].Version, getTicketsData.tickets[x].Fecha, getTicketsData.tickets[x].IdStatus, getTicketsData.tickets[x].nombreUsuarioCreador))}
            setRows(rowsTicketsDt);    
      } 
    }

    checkData();
  }, [getTicketsData]);

  useEffect(() => {
    function checkData() {

      if (insertarTicketsData){
        setAsunto("");
        setModulo(0);
        setVersion("");
        setComentario("");
        setArchivos(null);
        setOpen(false);
        executeGetTickets();
        swal("Ticket creado correctamente.", "Su numero de ticket es: " +insertarTicketsData.num_ticket , "success");
      }
    }
    checkData();
  }, [insertarTicketsData, executeGetTickets]);

  useEffect(() => {
    function checkData() {
      if (detallesTicketData){
        setDetNumTicket(detallesTicketData.tickets[0].id)
        setDetAsunto(detallesTicketData.tickets[0].Asunto);
        setDetModulo(detallesTicketData.tickets[0].IdModulo);
        setDetVersion(detallesTicketData.tickets[0].Version);
        setDetStatus(detallesTicketData.tickets[0].IdStatus)
        setDetComentario(detallesTicketData.tickets[0].Comentario);
        setDetFecha(detallesTicketData.tickets[0].Fecha);
        setDetUsuarioCreador(detallesTicketData.tickets[0].nombreUsuarioCreador);
        setDetArchivos(detallesTicketData.tickets[0].imagenes);
        setHistorial(detallesTicketData.tickets[0].historial);
        
        /* Trae los datos de la base de datos */ 
      }
    }
    checkData();
  }, [detallesTicketData]);

  useEffect(() => {
    function checkData() {
      if (modificarTicketData){
        setDetAsunto(modificarTicketData.tickets[0].Asunto);
        setDetModulo(modificarTicketData.tickets[0].IdModulo);
        setDetVersion(modificarTicketData.tickets[0].Version);
        setDetStatus(modificarTicketData.tickets[0].IdStatus)
        setDetComentario(modificarTicketData.tickets[0].Comentario);
        setDetFecha(modificarTicketData.tickets[0].Fecha);
        setDetArchivos(modificarTicketData.tickets[0].imagenes);
        setOpen(false);
        executeGetTickets();
        swal("Ticket modificado correctamente.", "El estatus del ticket es: " +modificarTicketData.tickets[0].Estatus , "success");
      }
    }
    checkData();
  }, [modificarTicketData, executeGetTickets]);

  useEffect(() => {
    function checkData() {
      if (getModulosTicketData){
        setModulos(getModulosTicketData.modulos);

      }
    }
    checkData();
  }, [getModulosTicketData]);

  useEffect(() => {
    function checkData() {
      if (getStatusTicketData){
        setStatus(getStatusTicketData.status);

      }
    }
    checkData();
  }, [getStatusTicketData]);

  useEffect(() => {
    function checkData() {
      if (eliminarImagenTicketData){
        setDetArchivos(eliminarImagenTicketData.imagenes);
      }
    }
    checkData();
  }, [eliminarImagenTicketData]);

  useEffect(() => {
    function checkData() {
      if (agregarImagenTicketData){
        setDetArchivos(agregarImagenTicketData.imagenes);
      }
    }
    checkData();
  }, [agregarImagenTicketData]);

  useEffect(() => {
    let newrows = [];
    if(filtro.trim() !== "" ){
      for(let x=0; x<rowsTicketsDt.length; x++){
        if(rowsTicketsDt[x].num_ticket.toString().toLowerCase().indexOf(filtro.toLowerCase().trim())!== -1 || 
          rowsTicketsDt[x].asunto.toLowerCase().indexOf(filtro.toLowerCase().trim())!== -1 || 
          rowsTicketsDt[x].modulo.toLowerCase().indexOf(filtro.toLowerCase().trim())!== -1 ||
          rowsTicketsDt[x].usuario.toLowerCase().indexOf(filtro.toLowerCase().trim())!== -1 ||
          rowsTicketsDt[x].estatus.toLowerCase().indexOf(filtro.toLowerCase().trim())!== -1 || 
          rowsTicketsDt[x].version.toString().toLowerCase().indexOf(filtro.toLowerCase().trim())!== -1 || 
          rowsTicketsDt[x].fecha.toLowerCase().indexOf(filtro.toLowerCase().trim())!== -1){
          newrows.push(rowsTicketsDt[x]);          
        }
      }
    }
  
    console.log(filtro.trim() === "");
    setRows(filtro.trim() === "" ? rowsTicketsDt : newrows);
  }, [filtro]);

  if(getTicketsLoading || insertarTicketsLoading || detallesTicketLoading || modificarTicketLoading || getStatusTicketLoading || getModulosTicketLoading || eliminarImagenTicketLoading || agregarImagenTicketLoading){
    return  <Typography>  Cargando...</Typography>
  } 
  if(getTicketsError || insertarTicketsError || detallesTicketError || modificarTicketError || getStatusTicketError || getModulosTicketError || eliminarImagenTicketError || agregarImagenTicketError){
    return <Typography> Error de conexion</Typography>
  }
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    setShowSection(1);
    executeDetallesTicket({
      params: {
        idTicket: id
      }
    });
  
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClickGuardarTicket=()=>{
    if(asunto.trim() === "") {
      swal("Error", "Ingrese un asunto", "warning");
    }
    else if(modulo === 0) {
      swal("Error", "Seleccione un módulo", "warning");
    }
    else if(version.trim() === "") {
      swal("Error", "Ingrese una version", "warning");
    }
    else if(comentario.trim() === "") {
      swal("Error", "Ingrese un comentario", "warning");
    }
    else {
      const formData = new FormData();
      formData.append("asunto", asunto);
      formData.append("modulo", modulo);
      formData.append("version", version);
      formData.append("comentario", comentario);
      formData.append("idStatus", 1);
      formData.append("Fecha", moment().format("YYYY-MM-DD"));
      formData.append("CodigoFecha", moment().format("YYYYMMDDHmmss"));
      formData.append("IdUsuario", userData.idusuario);
      if(archivos) {
        for (let x = 0; x < archivos.length; x++) {
        formData.append("documento" + x, archivos[x]);
        }
      }
        executeInsertarTickets({
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
        } 
      })
    }
  }

  const handleClickModificarTicket=()=>{
    if(detAsunto.trim() === "") {
      swal("Error", "Ingrese un asunto", "warning");
    }
    else if(detModulo === 0) {
      swal("Error", "Seleccione un módulo", "warning");
    }
    else if(detVersion.toString().trim() === "") {
      swal("Error", "Ingrese una version", "warning");
    }
    else if(detComentario.trim() === "") {
      swal("Error", "Ingrese un comentario", "warning");
    }
    else {
      executeModificarTickets({
        data: {
          idTicket: detNumTicket,
          asunto: detAsunto, 
          modulo: detModulo,
          version: detVersion,
          comentario: detComentario,
          idStatus: detStatus,
          UltimaModificacion: detFecha,
          archivos: detArchivos,  
          idusuario: userData.idusuario,
        }
      })
    }
  }
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return redirect ? (
      <Redirect to="/LoginTicket"/>
    )
    : (
      <Container maxWidth="md" className={classes.fondo}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} >
            <img src={Logo} alt="Logo" className={classes.Imagen} onClick={()=>{
              setShowSection(0);
              setFiltro("");
              }}></img>   
              <Typography className={classes.gridspace} style={{float:"right", cursor: "pointer"}} onClick={()=> {
              swal({
                text: '¿Quieres cerrar sesión?',
                buttons: ["No", "Sí"],
                dangerMode: true,
              }).then((value) => {
                if(value) {
                  localStorage.removeItem("userData");
                  setRedirect(true);
                }
                });
              }}> <u>Cerrar Sesión </u>
            </Typography>
          </Grid>
          <Grid item xs={12}> <Typography className={classes.Titulo}>Módulo de Desarrollo Franco Cabanillas </Typography>
            <br/>
            <Button variant="outlined" className={classes.btnblue} onClick={handleClickOpen}>
                Abrir nuevo ticket
            </Button>            
          </Grid> 
          <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <br/>
              <DialogTitle id="form-dialog-title" className={classes.Titulo2} textAlign="center" > NUEVO TICKET</DialogTitle>
                <DialogContent>
                  <Grid container spacing={3}> 
                    <Grid item xs={12}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="asunto"
                        label="Asunto"
                        type="text"
                        fullWidth
                        value={asunto}
                        onChange={(e) => {
                          setAsunto(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.textFields}
                        select
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                        label="Módulo"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        value={modulo}
                        onChange={(e) => {
                          setModulo(parseInt(e.target.value));
                        }}
                        >
                        <option value={0}>Selecciona un módulo</option>
                        {modulos.map((modulo, index) => (
                          <option value={modulo.id} key= {index}>
                            {modulo.Nombre}
                          </option>
                        ))}
                     </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="version"
                        label="Versión"
                        type="text"
                        fullWidth
                        value={version}
                        onChange={(e) => {
                          setVersion(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="comentarios"
                        label="Comentarios"
                        type="text"
                        fullWidth
                        value={comentario}
                        onChange={(e) => {
                          setComentario(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField id="imagenes" label="Archivos"  type="file" inputProps={{multiple: true,}} 
                        onChange={(e) => {
                          setArchivos(e.target.files);
                      }} />
                    </Grid>             
                  </Grid>
                </DialogContent>
                <DialogActions >
                  <Button onClick={handleClose} color="primary">
                    Cancelar
                  </Button>
                  <Button onClick={handleClickGuardarTicket} color="primary" type="submit">
                    Crear Ticket
                  </Button>
                </DialogActions>
            </Dialog>
          </div>         
          {showSection === 0 ? (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="filtro"
                    label="Búsqueda general..."
                    type="text"
                    fullWidth
                    value={filtro}
                    onChange={(e) => {
                    setFiltro(e.target.value);
                  }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className={classes.textFields}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    label="Búsqueda por estatus..."
                    InputLabelProps={{
                    shrink: true,
                    }}
                    margin="normal"
                    onChange={(e) => {
                      let newrows=[];
                      for(let x=0; x<rowsTicketsDt.length; x++){
                        if(rowsTicketsDt[x].idStatus === parseInt(e.target.value)){
                          newrows.push(rowsTicketsDt[x]);
                        }
                      }
                      console.log(rowsTicketsDt);
                      setRows(parseInt(e.target.value) === 0 ? rowsTicketsDt : newrows);
                    }}
                  >
                    <option value={0}>Selecciona un Estatus</option>
                    {status.map((status, index) => (
                      <option value={status.id} key= {index}>
                        {status.Nombre}
                      </option>
                    ))}    
                  </TextField>
                </Grid>
              </Grid> 
              <div className={classes.root}>
                <Paper className={classes.paper}>
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size={ 'medium'}
                      aria-label="enhanced table">
                      <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                      />
                      <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.name);
                          const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row.num_ticket)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.num_ticket}
                                selected={isItemSelected}
                                >
                                <TableCell component="th" id={labelId} scope="row" padding="none">
                                  {/* Alineacion de la tabla */}  
                                  {row.num_ticket}
                                </TableCell>
                                <TableCell align="center">{row.asunto}</TableCell>
                                <TableCell align="center">{row.modulo}</TableCell>
                                <TableCell align="center">{row.usuario}</TableCell>
                                <TableCell align="center">{row.estatus}</TableCell>
                                <TableCell align="center">{row.version}</TableCell>
                                <TableCell align="center">{row.fecha}</TableCell>
                              </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  labelRowsPerPage="Filas por página"
                  labelDisplayedRows={(e) => {
                    return `${e.from}-${e.to} de ${e.count}`;
                  }}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </div>
            </Grid>
          /* Detalles del ticket */
          ) : (
              <Grid container spacing={3} className={classes.gridspace}> 
                <Grid item xs={12}>
                  <Typography className={classes.Titulo2} textAlign="center"> Detalles del Ticket</Typography>
                </Grid>
                  <Grid item xs={6}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="id"
                      label="Número de ticket"
                      type="text"
                      fullWidth
                      value={detNumTicket}
                      onChange={(e) => {
                        setDetNumTicket(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="asunto"
                      label="Asunto"
                      type="text"
                      fullWidth
                      value={detAsunto}
                      onChange={(e) => {
                        setDetAsunto(e.target.value);
                      }}
                    />
                  </Grid>                      
                  <Grid item xs={6}>
                    <TextField
                      className={classes.textFields}
                      select
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                      label="Módulo"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                      value={detModulo}
                      onChange={(e) => {
                        setDetModulo(parseInt(e.target.value));
                      }}
                      >
                      <option value={0}>Selecciona un módulo</option>
                        {modulos.map((modulo, index) => (
                          <option value={modulo.id} key= {index}>
                            {modulo.Nombre}
                          </option>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      className={classes.textFields}
                      select
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                      label="Estatus"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                      value={detStatus}
                      onChange={(e) => {
                        setDetStatus(parseInt(e.target.value));
                      }}
                      >
                      <option value={0}>Selecciona un Estatus</option>
                      {status.map((status, index) => (
                        <option value={status.id} key= {index}>
                          {status.Nombre}
                        </option>
                      ))}                    
                    </TextField>
                  </Grid>                  
                  <Grid item xs={6}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="comentarios"
                      label="Comentarios"
                      type="text"
                      fullWidth
                      value={detComentario}
                      onChange={(e) => {
                        setDetComentario(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      autoFocus
                      InputProps={{
                        readOnly: true,
                      }}
                      margin="dense"
                      id="fecha"
                      label="Fecha de creación"
                      type="date"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={detFecha}
                      onChange={(e) => {
                        setDetFecha(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="version"
                      label="Versión"
                      type="text"
                      fullWidth
                      value={detVersion}
                      onChange={(e) => {
                        setDetVersion(e.target.value);
                      }}
                    />
                  </Grid>  
                  <Grid item xs={6}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="detUsuarioCreador"
                      label="Ticket creado por: "
                      type="text"
                      fullWidth
                      value={detUsuarioCreador}
                      onChange={(e) => {
                        setDetUsuarioCreador(e.target.value);
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  {/* TABLA DE HISTORIAL DE CAMBIOS DE ESTATUS */}
                  <Grid item xs={6}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 100 }}>
                        <TableHead className={classes.tblhstts}>
                          <TableRow className={classes.tblhstts}>
                            <TableCell align="center" colSpan={3}>
                             <b> Historial de cambios de estatus</b>
                            </TableCell>
                          </TableRow>
                          <TableRow className={classes.tblhstts}>
                            <TableCell>Usuario</TableCell>
                            <TableCell align="right">Estatus</TableCell>
                            <TableCell align="right">Fecha</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {historial.map((fila) => (
                            <TableRow key={fila}>
                              <TableCell>{fila.usuario}</TableCell>
                              <TableCell align="right">{fila.statusTicket}</TableCell>
                              <TableCell align="right">{fila.fecha}</TableCell>                             
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12} style={{textAlign:"center"}}> 
                    <Typography><h4 className={classes.greys}>Archivos</h4></Typography>
                    <Grid container>
                      {detArchivos.length>0?
                      detArchivos.map((archivo,index) => {
                        return(
                          <Grid item xs={4} key={index}>
                            <a href={archivo.urlImagen} target="_blank" rel="noreferrer">
                              <img src={archivo.urlImagen+"/preview"} alt={archivo.nombreImagen} width="90%" height="150px" style={{marginTop:"10px"}}/>  
                            </a>
                            <Button variant="outlined" className={classes.buttondelete} onClick={()=> {
                              executeEliminarImagenTicket({
                                data: {
                                  idImagenTicket: archivo.id
                                }
                              })
                            }} >Eliminar archivo</Button>
                          </Grid>
                        )
                      }):(
                        <Typography>No se agregaron archivos </Typography>
                      )}
                      <Grid item xs={12} style={{textAlign:"left"}}>  
                        <TextField id="imagenes" label="Archivos"  type="file"  inputProps={{multiple: true,}} 
                          onChange={(e) => {
                            const formData = new FormData();
                            formData.append("num_Ticket",detNumTicket);
                            formData.append("codigoFecha", moment().format("YYYYMMDDHmmss"));

                            if(e.target.files.length>0) {
                              for (let x = 0; x < e.target.files.length; x++) {
                              formData.append("documento" + x, e.target.files[x]); 
                              }
                              executeAgregarImagenTicket({
                                data: formData,
                                headers: {
                                  "Content-Type": "multipart/form-data",
                                } 
                              })
                            }                     
                        }} /> 
                        <br/>
                      </Grid>  
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{textAlign:"center"}}>        
                    <Button variant= "outlined" onClick={handleClickModificarTicket} className={classes.btnblue} type="submit" display="grid" >Guardar cambios</Button>
                  </Grid> 
                  <Grid item xs={12}>
                    <Typography className={classes.gridspace} style={{cursor: "pointer"}} onClick={()=>{
                      setShowSection(0);
                      }} > <u className={classes.greys}>
                      Regresar a la tabla </u>
                    </Typography>
                  </Grid>               
              </Grid>
          ) }   
        </Grid>
      </Container>
    )
}